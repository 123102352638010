<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: 'facturacion.ordenes_pago',
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : this.data_table && this.data_table.length > 0
                  ? `(${this.data_table.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-text-field
            v-model="search_table"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Mostrar"
            v-model="filter"
            :items="filter_options"
            item-value="id"
            :item-text="(v) => v.value"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data_table"
            :loading="loading"
            :search="search_table"
          >
            <template v-slot:item.index="{ item }">
              <div>
                <span class="font-weight-bold" v-text="item.index" />
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    dark
                    @click.prevent="statusAccountLoad(item)"
                  >
                    <v-icon v-text="'mdi-file-eye'" />
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog
      v-model="status_account_dialog"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        v-if="status_account_dialog"
        :loading="status_account_dialog_loading"
        :disabled="status_account_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `Estado de cuenta${
                status_account.length > 0 ? ` (${status_account.length})` : ''
              }`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="statusAccountDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'INFORMACIÓN'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12" v-if="status_account_load">
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="status_account_load"
                      class="text-right"
                    >
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            x-small
                            fab
                            outlined
                            class="mr-1"
                            color="warning"
                            :href="
                              url_documents +
                              '/rha_bill_status_account_faileds/' +
                              data.url_file
                            "
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" v-if="status_account_load">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-text="'#'" />
                              <th v-text="'Fecha'" />
                              <th v-text="'Banco beneficiario'" />
                              <th v-text="'Nombre beneficiario'" />
                              <th v-text="'Cuenta beneficiario'" />
                              <th v-text="'Referencia'" />
                              <th v-text="'Referencia númerica'" />
                              <th v-text="'Concepto'" />
                              <th v-text="'Concepto pago'" />
                              <th v-text="'Importe'" />
                              <th v-text="'Monto a pagar'" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(v, i) in status_account"
                              :key="i"
                              :class="getclassRowStatusAccount(v)"
                            >
                              <td v-text="i + 1" />
                              <td v-text="v.fecha" />
                              <td v-text="v.banco_beneficiario" />
                              <td v-text="v.nombre_beneficiario" />
                              <td v-text="v.cuenta_beneficiario" />
                              <td v-text="v.referencia" />
                              <td v-text="v.referencia_numerica" />
                              <td v-text="v.concepto" />
                              <td v-text="v.concepto_pago" />
                              <td
                                v-text="numberFormat(v.importe)"
                                :class="
                                  v.amount
                                    ? v.importe === parseFloat(v.amount)
                                      ? ''
                                      : 'red--text'
                                    : ''
                                "
                              />
                              <td
                                v-text="v.amount ? numberFormat(v.amount) : '-'"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  URL_DOCUMENTS,
} from "../../control";
import { storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      api: "bills",
      page_route: "facturacion",
      login: this.$store.getters.getLogin,
      url_documents: URL_DOCUMENTS,
      loading: false,
      rules: rules(),
      data_table: [],
      data: null,
      search_table: "",
      headers: [
        { text: "#", filterable: true, value: "index" },
        { text: "Folio", filterable: true, value: "folio" },
        {
          text: "Usuario",
          filterable: true,
          value: "created_name",
        },
        { text: "Fecha", filterable: true, value: "created_at" },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "250px",
        },
      ],
      filter: 1,
      filter_options: [
        { id: 1, value: "PENDIENTES" },
        { id: 2, value: "PAGADO SIN VERIFICAR" },
        { id: 3, value: "VERIFICADOS Y TERMINADOS" },
      ],
      status_account: [],
      status_account_dialog: false,
      status_account_dialog_loading: false,
      status_account_load: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleTableColumns() {},
    getData() {
      this.loading = true;

      Axios.get(
        URL_API + `/bills/rha/payment/status_account/failed`,
        headersToken(this.login.token)
      ).then((response) => {
        console.log(response.data);
        this.data_table = response.data.data;
        this.loading = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    statusAccountDefault() {
      this.status_account_dialog_loading = false;
      this.status_account = [];
      this.status_account_load = false;
    },
    statusAccountDialogClose() {
      this.status_account_dialog = false;
      this.statusAccountDefault();
    },
    statusAccountLoad(rha_bill_status_account_failed) {
      this.data = rha_bill_status_account_failed;
      this.status_account_load = false;
      this.status_account = {};
      this.status_account_dialog_loading = true;
      this.status_account_dialog = true;

      storeUpdate(
        "bills/rha/payment/status_account",
        this.login.token,
        null,
        {
          url_file: this.data.url_file,
        },
        null
      )
        .then((response) => {
          if (response.success) {
            this.status_account = response.data;
            this.status_account_load = true;
          } else {
            console.log(e);
            this.$swal.fire(msgAlert("error", "Formato de documento invalido"));
          }

          this.status_account_dialog_loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire(msgAlert("error", "Formato de documento invalido"));
          this.status_account_dialog_loading = false;
        });
    },
    getclassRowStatusAccount(v) {
      if (v.paid_check) return "orange lighten-3";
      if (v.exist > 1) return "red lighten-3";
      if (v.rha_bill_payment_item_id) return "green lighten-3";
    },
  },
  computed: {},
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });
    this.getData();
  },
};
</script>